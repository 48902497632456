import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: ${theme.breakpoints.large}px;
    padding: 0 ${theme.gutters.small}px;

    ${theme.queries.large} {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`

export default Wrapper
