/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import React from 'react'
import { ThemeProvider } from 'emotion-theming'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'

import theme from './theme'
import fontStyles from './font-styles'
import resetStyles from './reset-styles'

const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    background: ${theme.colors.aliceblue};
    min-height: 100vh;
    min-width: 320px;
    overflow-x: hidden;
    position: relative;
  `}
`

const Layout = ({ children }) => (
  <>
    <Helmet>
      <meta name="viewport" content="width=device-width, user-scalable=no" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Container>
        <Global styles={css(resetStyles, fontStyles)} />
        <main>{children}</main>
      </Container>
    </ThemeProvider>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
