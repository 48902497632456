/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import styled from '@emotion/styled'
import { useState, useEffect } from 'react'
import { toast } from 'react-toastify'

import { navigate } from 'gatsby'
import Layout from '../components/Layout/landing'
import Wrapper from '../components/Wrapper'
import Button from '../components/Button'
import Spacing from '../components/Spacing'

import { fetchAllInstitutions } from '../services/institutions'
import { fetchAllCampaigns } from '../services/campaigns'

const Container = styled.div`
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 10px #7c7c7c80;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 40px auto 0;
  padding: 30px;
  width: 90%;
  max-width: 700px;
  position: relative;
`

const Select = styled.select`
  ${({ theme }) => css`
    background: #fff;
    border: 0;
    border-bottom: 2px solid ${theme.colors.jet};
    border-radius: 0;
    font-size: 18px;
    font-weight: 300;
    height: 51px;
    padding-left: 5px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin-bottom: 20px;

    ${theme.queries.medium} {
      border: 0;
      border-bottom: 2px solid ${theme.colors.jet};
      border-radius: 0;
      flex: 1;
    }
  `}
`

const Live = () => {
  const [institutions, setInstitutions] = useState([])
  const [campaigns, setCampaign] = useState([])

  const [selectedInstitution, setSelectedInstitution] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const handleInstitutionSelect = () => {
    navigate(`/live/${selectedInstitution}/`)
  }

  const handleCampaignSelect = () => {
    const data = JSON.parse(selectedCampaign)
    navigate(`/live/${data.institution}/campanha/${data.id}`)
  }

  const loadInstitutionsData = async () => {
    try {
      const response = await fetchAllInstitutions()
      const institutionsData = response.data.results.map(institutions => ({
        id: institutions.id,
        name: institutions.tradingName,
      }))

      setInstitutions(institutionsData)
    } catch (error) {
      if (error.data.message) {
        toast.info(error.data.message)
      }
      toast.warn(error)
    }
  }

  const loadCampaignData = async () => {
    try {
      const response = await fetchAllCampaigns()
      const campaignsData = response.data.results.map(campaigns => ({
        id: campaigns.id,
        name: campaigns.campaignName,
        institution: campaigns.institutionId,
      }))

      setCampaign(campaignsData)
    } catch (error) {
      if (error.data.message) {
        toast.info(error.data.message)
      }
      toast.warn({ error })
    }
  }

  useEffect(() => {
    loadInstitutionsData()
    loadCampaignData()
  }, [])

  return (
    <Layout>
      <Wrapper>
        <Container>
          <Spacing top="30" topMobile="30">
            <Select onBlur={e => setSelectedInstitution(e.target.value)}>
              <option value="" selected disabled>
                Selecione
              </option>
              {institutions.map(institution => (
                <option key={institution.id} value={institution.id}>
                  {institution.name}
                </option>
              ))}
            </Select>

            <Button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              type="button"
              size="small"
              onClick={handleInstitutionSelect}
            >
              Ir para Instituição
            </Button>
          </Spacing>

          <Spacing top="30" bottom="30" topMobile="30" bottomMobile="30">
            <Select onBlur={e => setSelectedCampaign(e.target.value)}>
              <option value="" selected disabled>
                Selecione
              </option>
              {campaigns.map(campaign => (
                <option
                  key={campaign.id}
                  value={JSON.stringify({
                    id: campaign.id,
                    institution: campaign.institution,
                  })}
                >
                  {campaign.name}
                </option>
              ))}
            </Select>

            <Button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              type="button"
              color="secondary"
              size="small"
              onClick={handleCampaignSelect}
            >
              Ir para Campanha
            </Button>
          </Spacing>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Live
