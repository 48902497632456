import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const SIZE_MAP = {
  default: {
    fontSize: 14,
    minHeight: '52px',
    padding: '18px 26px',
  },
  small: {
    fontSize: 12,
    padding: '14px 26px',
  },
}

const STYLE_MAP = {
  primary: {
    backgroundColor: '#4B99C1',
    border: 0,
    borderColor: '#4B99C1',
    color: '#FFFFFF',
  },
  secondary: {
    backgroundColor: '#95CCF2',
    border: 0,
    borderColor: '#95CCF2',
    borderWidth: 2,
    color: '#245674',
  },
  transparent: {
    backgroundColor: '#FFFFFF',
    borderColor: '#4B99C1',
    color: '#4B99C1',
  },
  neutral: {
    backgroundColor: 'transparent',
    border: 0,
    color: '#414141',
    fontSize: 'initial',
    padding: 0,
  },
}

const getStyles = color => STYLE_MAP[color]
const getSize = size => SIZE_MAP[size]

const Button = styled(motion.button)`
  ${({ color, size, uppercase, stretch }) => css`
    border-radius: 43px;
    border-width: 2px;
    cursor: pointer;
    font-weight: 400;
    font-family: 'MontSerrat';
    outline: none;
    position: relative;
    ${uppercase && `text-transform: uppercase`};
    ${stretch && `width: 100%`};
    ${getSize(size)};
    ${getStyles(color)};

    svg {
      height: 100%;
      left: calc(50% - 30px);
      position: absolute;
      top: 0;
      width: auto;
    }
  `};
`

Button.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  uppercase: PropTypes.bool,
  stretch: PropTypes.bool,
}

Button.defaultProps = {
  size: 'default',
  color: 'primary',
}

export default Button
