import { css } from '@emotion/core'
import styled from '@emotion/styled'

const Spacing = styled.div`
  ${({ theme, top, topMobile, bottom, bottomMobile }) => css`
    ${topMobile && `margin-top: ${topMobile}px`};
    ${bottomMobile && `margin-bottom: ${bottomMobile}px`};

    ${theme.queries.medium} {
      ${top && `margin-top: ${top}px`};
      ${bottom && `margin-bottom: ${bottom}px`};
    }
  `};
`

export default Spacing
